import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ListItem extends React.Component {
  render () {
    const { siteMetadata, data, pathPrefix, showAuthor } = this.props;
    const { excerpt, title, slug, path, featured_image_url, author, date } = data
    return (
      <div className="post-list-item">
        <div>
          <div className="image"><GatsbyLink to={path} label={title}><Image src={featured_image_url} className="background" /></GatsbyLink></div>
          <div className="inner">
            <h4 className="title"><GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink></h4>
            {excerpt && <RenderContent content={`${excerpt}`}/>}
            {slug && <GatsbyLink to={path} label={title} className="post-link"><FaChevronRight/></GatsbyLink>}
          </div>
          <div className="meta">
            {showAuthor && author && <div className="avatar"><Image src={author.avatar_urls.wordpress_48}/></div>}
            <div>
              {author && showAuthor && <GatsbyLink to={author.path} className="author">{author.name}</GatsbyLink>}
              {/*date && <span className="date">{date}</span>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
